body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* max-width:1200px; */
	/* background-color: #f0f0f0; */
	margin: 0 auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* 
	HEADER
*/

/* header {
	box-shadow: 0 5px 5px #999;

	justify-content: space-between;
} */



.header-detail {
	margin: 8px;
	/* display:block; */
	/* margin-left: auto; */
}

.avatar-popover-container {
	min-width: 12em;
}

/* 
	NAVIGATION
*/

nav.desktop ul {
	list-style-type: none;
	margin: 6px;
	padding: 0;
	overflow: hidden;

}

nav.desktop ul li {
	float: left;
}

nav.mobile-nav ul {
	list-style-type: none;
	padding: 0;
	/* overflow: hidden; */
}

nav.mobile-nav ul li {
	border: 1px solid red;
	display: block;
	margin: 6px;
	/* float: left; */
}

.nav-link {
	display: block;
	padding: 3px;
	text-decoration: none;
	text-align: center;
	color: #222;
	border-radius: 8px;
	border: solid 1px #ddd;
}

.nav-link:hover {
	background-color: #ccc;
	border: solid 1px #aaa;
}

.sub-nav-link {
	display: block;
	padding: 12px;
	text-decoration: none;
	text-align: center;
	color: #222;
	border-radius: 7px;
	border: solid 1px #ddd;
}

.sub-nav-link:hover {
	background-color: #ccc;
	border: solid 1px #aaa;
}

footer {
	display: flex;
}

#footer-content {
	margin-left: auto;
	margin-right: auto;
}

/* 
	FORMS AND INPUTS
*/

.input-group {
	margin: 8px;
}

input {
	appearance: auto ! important;
}

h3 {
	margin-top: 15px;
}

.component-list-item ul {
	list-style-type: none;
}

.sub-layout.title {
	font-size: 1.25em;
}

.title {
	font-size: 2em;
}

/* .hidden-span-calc {
	display: none;
	position: absolute;
	opacity: 0;
} */

div.list-item {
	margin: 20px 10px;
	padding: 15px;
	border-radius: 15px;
	/* box-sizing: border-box; */
	border: 2px solid rgba( 0, 0, 0, 0);
	transition: .2s;
	transition-timing-function: ease-out;
	/* cursor: pointer; */
	background-color: #eee;
	/* background-color: #f0f0f0; */
	overflow: hidden;
	/* z-index: 2; */
}
div.list-item:hover {
	border: 2px solid #555;
	/* background-color: #f0f0f0; */
}





.event-hover-edit {
	/* display: none; */
	opacity: 0;
	float: right;
	overflow: hidden;
	transition: opacity .2s;
	/* width: 100px;
	position: relative;
	right: 0;
	bottom: 0; */
}

.list-item:hover .event-hover-edit {
	/* display: inline-block; */
	opacity: 1;
}



.help-info {
	background-color: rgba( 255, 255, 255, 1 );
	border-radius: 6px;
}

.help-info > div {
	padding: 1em;
	background-color: rgba( 255, 0, 111, .1 );
	border: 2px solid rgba( 255, 0, 111, 1 );
	border-radius: 6px;
}




.date-string-clickable {
	border: 2px solid rgba( 0, 0, 0, 0 );
	padding: 2px;
	border-radius: 4px;
}
.date-string-clickable:hover {
	border: 2px solid rgba( 255, 0, 111, .3 );
}



/* * * * * * * * * * * * * * * * * * * * * * * * * * 
	ICONS
* * * * * * * * * * * * * * * * * * * * * * * * * */

.big-icon {
	font-size: 3em;
}
/* .big-icon svg {
	display: block ! important;
} */

.medium-icon {
	font-size: 1.75em;
}

.small-icon {
	font-size: 1em;
}

button:has( > svg.medium-icon ) {
	height: 38px;
	width: 38px;
}



/* * * * * * * * * * * * * * * * * * * * * * * * * * 
	ICONS
* * * * * * * * * * * * * * * * * * * * * * * * * */

.ant-collapse-header {
	padding-top: .5em !important;
	padding-bottom: .5em !important;
	font-size: 1.25em;
}

.ant-collapse-header svg {
	font-size: 1.5em;
}
/* 
.ant-collapse-header .collapse-header-icon svg {
	font-size: 1.5em;
} */

.ant-collapse-header-text {
	width: 100%;
}





/* * * * * * * * * * * * * * * * * * * * * * * * * * 
	//
* * * * * * * * * * * * * * * * * * * * * * * * * */



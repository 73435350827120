
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

select {
	margin-top: 8px;
	margin-bottom: 8px;
}

/* .component-list-item {
	margin-bottom: 10px;
	padding: 8px;

	border-radius: 8px;
	border: 3px solid #999;
	background-color: #ccc;
}

.component-list-item .btn {
	margin: 4px;
} */


/* For mobile scroll on time select for datetime fields. TODO */
/* .ant-time-picker-panel .ant-time-picker-panel-select, .ant-picker-time-panel-column, .ant-picker-content, .ant-picker-time-panel, .ant-picker-datetime-panel, .ant-picker-date-panel {
	overflow-y: auto;

	@media ( pointer: course ) {
		overflow: hidden;
	}
} */





.component-title {
	font-size: 2em;
}

.component-detail {
	color: #222;
}

.component-attr {
	font-style: italic;
}

.component-attr strong {
	font-style: initial;
}


/* FORMS && INPUTS */
/* DefElement */
/* .element-wrapper {
	border-radius: 12px;
	border: 2px solid limegreen;
	padding: 9px;
	margin: 12px;
} */


.element-def-wrapper input, 
.element-def-wrapper select {
	background-color: rgba(255, 255, 255, .6) !important;
}



.element-def-wrapper {
	border-radius: 12px;
	border: 2px solid blueviolet;
	background-color: #e2caf8;
	padding: 10px;
	margin: 15px;
}



/* CHOICE LIST */
.choice-list-wrapper {
	border-radius: 12px;
	border: 2px solid dodgerblue;
	background-color: #d2e9ff;
	padding: 10px;
	margin: 15px;
}

.choice-list-item {
	border-radius: 12px;
	border: 2px solid crimson;
	background-color: #f8d0d8;
	padding: 10px;
	margin: 15px;
}



.dot {
	height: 1em;
	width: 1em;
	border-radius: 50%;
	display: inline-block;
	margin: 0 5px;
	transition: background-color .3s;
}



/* EVENT LIST - EVENTS - EVENT ELEMENTS */

/* .event-element {
	margin: 4px;
	border-radius: 8px;
	border-width: 2px;
	border-style: solid;
}

.event-element-background {
	padding: 4px;
	border-radius: 6px;
	background-color: rgba(255, 255, 255, .8);
}

.element-label {
	margin: 4px;
	font-size: .8em;
} */

.element-value {
	margin: 8px;
}

.element-icon {
	height: 1em;
	width: 1em;
	/* border-radius: 50%; */
	display: inline-block;
	margin: 0 5px;
	transition: background-color .3s;
}





/* .event-card .event-element {

} */
/* InputGroup  */

.input-label {
	width: 20%;
}

.input-status {
	width: 4em;
}

.input-status svg {
	/* allow icons to extend into InputGroup.Text padding */
	margin-top: -6px;
	margin-bottom: -6px;
}

.input-status-valid {
	color: green;
}

.input-status-invalid {
	color: tomato;
}

.input-status-nil {
	color: inherit;
}






.accordion-header-flex {
	display: flex;
	width: 100%;
}

.accordion-header-title {
	margin-right: auto;
}

.accordion-header-badges {
	margin-right: 8px;
}




.form-bundle {
	border-radius: 16px;
	padding: 12px;
	margin: 8px 0;
	transition: .2s;
	border: 2px solid #eee;
}

.form-bundle:hover {
	border: 2px solid #ccc;
	background-color: #eee;

}

